<template>
  <section class="p-3 h-100" style="background-color: #f0f0f0;">
    <b-row v-if="workspaces.length">
      <b-col cols="3">
        <b-input-group>
          <b-form-input type="text" v-model="search" placeholder="Search" />
          <b-input-group-append>
            <b-button variant="light"><icon name="search"></icon></b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="3" class="pl-0">
        <v-select
          :options="sortOptions"
          label="text"
          v-model="sort"
          :clearable="false"
        >
          <template #selected-option="{ text }">
            <span
              >Sort by: <strong>{{ text }}</strong></span
            >
          </template>
        </v-select>
      </b-col>
      <b-col v-if="!archiveSelected" class="text-right">
        <b-button @click="openCreateFormModal()" variant="primary">
          {{ $t("createform") }}
        </b-button>
      </b-col>
    </b-row>
    <b-row
      v-if="workspaces.length && !archiveSelected"
      class="mt-3 border-bottom"
    >
      <b-col>
        <div
          class="tab-select"
          :class="{ active: activeTab === 'all' }"
          @click="setActiveTab('all')"
        >
          All forms ({{ forms.length }})
        </div>
        <div
          class="tab-select"
          :class="{ active: activeTab === 'active' }"
          @click="setActiveTab('active')"
        >
          Active forms ({{ countActiveForms }})
        </div>
        <div
          class="tab-select"
          :class="{ active: activeTab === 'closed' }"
          @click="setActiveTab('closed')"
        >
          Closed forms ({{ countClosedForms }})
        </div>
      </b-col>
    </b-row>
    <b-row v-if="archiveSelected && forms.length" no-gutters>
      <b-col no-gutters>
        <b-alert class="alert-archive" show variant="warning">
          <b-icon
            class="h4 mr-2 mb-0"
            icon="exclamation-circle-fill"
            variant="warning"
          />
          <span>
            {{ $t("archiveWarning") }}
          </span>
        </b-alert>
      </b-col>
    </b-row>
    <b-container fluid class="flex-grow-1 px-0 py-3">
      <b-row no-gutters>
        <b-col
          style="max-width: 205px; margin-right:12px;"
          v-for="(form, index) of searchForms"
          :key="index"
        >
          <WorkspaceMainFormCard
            :form="form"
            :teamId="teamId"
            :workspaceId="workspaceId"
            :workspaces="workspaces"
            :archive-selected="archiveSelected"
            @go-to-form="goToForm(form.id, form.template)"
            @delete-form="deleteForm(form.id)"
            @archive-form="archiveForm(form.id)"
            @duplicate-form="openDuplicateFormModal(form.id)"
            @rename-form="openRenameFormModal(form.id, form.title)"
            @move-form="formMove($event)"
            @restore-form="restoreForm($event)"
          />
        </b-col>
        <div
          v-if="forms.length === 0 && workspaces.length && !archiveSelected"
          class="create-form-wrapper"
        >
          <icon name="create-form"></icon>
          <h4 class="m-0">{{ $t("createFirstForm") }}</h4>
          <p style="color: #5E6674">{{ $t("createFirstFormDesc") }}</p>
        </div>
        <div v-if="!workspaces.length" class="create-form-wrapper">
          <icon name="create-form"></icon>
          <h4 class="m-0">{{ $t("createFirstWorkspace") }}</h4>
        </div>
        <div
          v-if="!searchForms.length && forms.length"
          class="create-form-wrapper"
        >
          <icon name="search-not-found"></icon>
          <h4 class="m-0">{{ $t("searchNotFound", { search }) }}</h4>
        </div>
        <div
          v-if="archiveSelected && !forms.length"
          class="create-form-wrapper"
        >
          <icon name="search-not-found"></icon>
          <h4 class="m-0">{{ $t("trashEmpty") }}</h4>
          <p style="color: #5E6674">{{ $t("trashEmptyDesc") }}</p>
        </div>
      </b-row>
    </b-container>

    <b-modal centered id="create-form" no-stacking hide-footer>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("createform") }}</h5>
      </template>
      <template #default>
        <div class="p-1 d-flex flex-row">
          <b-card
            no-body
            class="form-wrapper cursor-pointer mr-2"
            @click="openFormNameModal(formTypeScratch)"
          >
            <div class="image-container overflow-hidden">
              <div class="card-field">
                <span class="field-title">Java Jazz Registration</span>
                <span class="field-description"
                  >Please fill this form with your real data, so we could send
                  the ticket for you</span
                >
                <div class="start-page mt-1">Start</div>
              </div>
              <div class="card-field mt-1">
                <span class="field-title">What is your name?</span>
                <div class="short-text mt-1" />
              </div>
            </div>
            <b-card-body class="p-3">
              <span class="form-title">{{ $t("createFromScratch") }}</span>
            </b-card-body>
          </b-card>
          <!-- <b-card
            no-body
            class="form-wrapper cursor-pointer"
            @click="openFormNameModal(formTypeQuiz)"
          >
            <div class="image-container overflow-hidden">
              <div class="card-field">
                <span class="field-title">Quiz Editor Page</span>
                <span class="field-description"
                  >Please fill this form with your real data, so we could send
                  the ticket for you</span
                >
                <div class="start-page mt-1">Start</div>
              </div>
              <div class="card-field mt-1">
                <span class="field-title">What is your name?</span>
                <div class="short-text mt-1" />
              </div>
            </div>
            <b-card-body class="p-3">
              <span class="form-title">Quiz Page</span>
            </b-card-body>
          </b-card> -->
        </div>
      </template>
    </b-modal>

    <b-modal
      :id="formNameModal.id"
      :title="$t('createform')"
      centered
      :busy="formNameModal.isLoading"
      @ok.prevent="formNameModal.okHandler"
      @hide="onFormNameModalHide"
    >
      <b-form class="mt-3" @submit.prevent="formNameModal.okHandler">
        <b-form-group :label="$t('formName')">
          <b-form-input
            v-model.trim.lazy="$v.formNameModal.formName.$model"
            type="text"
            required
            :state="validateState('formName')"
            :disabled="formNameModal.isLoading"
            @blur="$v.formNameModal.formName.$touch()"
          />
          <b-form-invalid-feedback>{{
            $t("formNameErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      :id="formDuplicateNameModal.id"
      :title="$t('duplicateform')"
      centered
      :busy="formDuplicateNameModal.isLoading"
      @ok.prevent="formDuplicateNameModal.okHandler"
      @hide="onFormDuplicateNameModalHide"
    >
      <b-form class="mt-3" @submit.prevent="formDuplicateNameModal.okHandler">
        <b-form-group :label="$t('formName')">
          <b-form-input
            v-model.trim.lazy="$v.formDuplicateNameModal.formName.$model"
            type="text"
            required
            :state="validateDuplicateState('formName')"
            :disabled="formDuplicateNameModal.isLoading"
            @blur="$v.formDuplicateNameModal.formName.$touch()"
          />
          <b-form-invalid-feedback>{{
            $t("formNameErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal id="rename-form" centered>
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("renameForm") }}</h5>
      </template>
      <b-form class="mt-3">
        <b-form-group :label="$t('enterNewName')">
          <b-form-input v-model="updateFormName.title" type="text" required />
          <b-form-invalid-feedback>{{
            $t("formNameErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button size="md" variant="primary" @click="formRename()">
          {{ $t("save") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="survein-full" centered>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("titleSurveinFull") }}</h5>
      </template>
      <icon name="survein-full" />
      <span>{{ $t("surveinFull") }}</span>
      <template #modal-footer>
        <b-button @click="openUpgradeModal()" variant="primary">{{
          $t("upgrade")
        }}</b-button>
      </template>
    </b-modal>

    <b-modal id="plan-expired" centered>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("needRenewal") }}</h5>
      </template>
      <icon name="survein-full" />
      <span>{{ $t("expiredReminder") }}</span>
      <template #modal-footer>
        <b-button @click="openRenewModal()" variant="primary">{{
          $t("renew")
        }}</b-button>
      </template>
    </b-modal>
    <SubscriptionModal :modalId="'subscription-renew'" ref="renewModal" />
  </section>
</template>

<i18n>
{
  "en": {
    "workspace": "Workspace",
    "createform": "Create Form",
    "duplicateform": "Duplicate Form",
    "renameForm": "Rename Form",
    "formDuplicated": "Form duplicated",
    "formRenamed": "Form renamed",
    "createFromScratch": "Create from scratch",
    "formName": "Form name",
    "formNameErrMsg": "Name is required.",
    "titleSurveinFull": "Your Survein is full",
    "surveinFull": "You’ve just reached plan limit. To continue using Survein, delete some forms or upgrade your plan for better features.",
    "upgrade": "Upgrade now",
    "renew": "Renew Plan",
    "needRenewal": "Your Survein need Renewal",
    "expiredReminder": "Some features are not working due to an expired plan, such as create new form or new workspace. Renew your plan to continue using Survein premium features.",
    "createFirstForm": "Start create your first form",
    "createFirstWorkspace": "Start create your first workspace",
    "createFirstFormDesc": "Click create form button to begin",
    "generalError": "Oops! Something went wrong, please try again",
    "enterNewName": "Please enter a new name for the form",
    "cancel": "Cancel",
    "save": "Save",
    "searchNotFound": "Sorry, we couldn’t find any matches for {search}",
    "formMoved": "Form move to {workspaceName} workspace successfully",
    "formRestored": "Form restored to {workspaceName} workspace successfully",
    "archiveWarning": "All forms are showing the days remaining before deletion. After that time, the form will be permanently deleted.",
    "trashEmpty": "Nothing in trash",
    "trashEmptyDesc": "Your trash completely empty."
  },
  "id": {
    "workspace": "Ruang kerja",
    "createform": "Buat Formulir",
    "duplicateform": "Duplikat Form",
    "renameForm": "Ubah nama form",
    "formDuplicated": "Berhasil menduplikat form",
    "formRenamed": "Berhasil mengubah nama form",
    "createFromScratch": "Buat dari awal",
    "formName": "Nama formulir",
    "formNameErrMsg": "Nama harus diisi.",
    "titleSurveinFull": "Survein anda sudah penuh",
    "surveinFull": "Anda baru saja mencapai batas paket. Untuk terus menggunakan Survein, hapus beberapa formulir atau tingkatkan paket Anda untuk fitur yang lebih baik.",
    "upgrade": "Tingkatkan",
    "renew": "Perbarui paket",
    "needRenewal": "Survein Anda butuh perpanjang paket",
    "expiredReminder": "Beberapa fitur tidak berfungsi karena paket kedaluwarsa, seperti buat formulir baru atau ruang kerja baru. Perpanjang paket Anda untuk terus menggunakan fitur premium Survein.",
    "createFirstForm": "Mulai buat formulir pertama Anda",
    "createFirstWorkspace": "Mulai buat Ruang Kerja pertama Anda",
    "createFirstFormDesc": "Klik tombol buat formulir untuk memulai",
    "generalError": "Oops! Terjadi kendala, silahkan coba kembali",
    "enterNewName": "Silakan masukkan nama baru untuk formulir",
    "cancel": "Batal",
    "save": "Simpan",
    "searchNotFound": "Maaf, kami tidak dapat menemukan yang cocok untuk {search}",
    "formMoved": "Formulir berhasil pindah ke ruang kerja {workspaceName}",
    "formRestored": "Formulir berhasil dipulihkan ke ruang kerja {workspaceName}",
    "archiveWarning": "Semua formulir menunjukkan sisa hari sebelum penghapusan. Setelah waktu itu, formulir akan dihapus secara permanen.",
    "trashEmpty": "Tempat sampah kosong",
    "trashEmptyDesc": "Sampah Anda benar-benar kosong."
  }
}
</i18n>

<script>
import WorkspaceMainFormCard from "@/components/Workspace/WorkspaceMainFormCard";
import { mapActions, mapGetters } from "vuex";
import { isAfter } from "date-fns";
import { required } from "vuelidate/lib/validators";
import SubscriptionModal from "../common/SubscriptionModal";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {
    WorkspaceMainFormCard,
    SubscriptionModal
  },
  props: {
    forms: {
      type: Array,
      default: () => []
    },
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    workspaces: {
      type: Array,
      required: true
    },
    archiveSelected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      formTypeScratch: "scratch",
      formTypeQuiz: "quiz",
      formNameModal: {
        id: "form-name-modal",
        formName: "",
        okHandler: null,
        isLoading: false
      },
      formDuplicateNameModal: {
        id: "form-duplicate-name-modal",
        formName: "",
        okHandler: null,
        isLoading: false
      },
      sortOptions: [
        {
          text: "Latest Form",
          value: "latest"
        },
        {
          text: "Oldest Form",
          value: "oldest"
        },
        {
          text: "A to Z",
          value: "asc"
        },
        {
          text: "Z to A",
          value: "desc"
        }
      ],
      search: "",
      sort: {
        text: "Latest Form",
        value: "latest"
      },
      activeTab: "all",
      updateFormName: {
        title: "",
        formId: null
      }
    };
  },
  computed: {
    ...mapGetters("form", ["form", "selectedVersion"]),
    ...mapGetters("team", ["selectedTeam"]),

    reminderPlanExpired() {
      let reminder = false;
      if (this.selectedTeam) {
        if (this.selectedTeam.licenseValidUntil !== null) {
          reminder = isAfter(Date.now(), this.selectedTeam.licenseValidUntil);
        }
      }
      return reminder;
    },
    filterForms() {
      return this.activeTab === "closed"
        ? this.forms.filter(form => !form.acceptingResponse)
        : this.activeTab === "active"
        ? this.forms.filter(form => form.acceptingResponse)
        : this.forms;
    },
    sortForms() {
      const forms = cloneDeep(this.filterForms);
      switch (this.sort.value) {
        case "latest":
          return forms.sort((a, b) => b.createdAt - a.createdAt);
        case "oldest":
          return forms.sort((a, b) => a.createdAt - b.createdAt);
        case "asc":
          return forms.sort((a, b) => a.title.localeCompare(b.title));
        case "desc":
          return forms.sort((a, b) => b.title.localeCompare(a.title));
        default:
          return forms;
      }
    },
    searchForms() {
      return this.search.length > 0
        ? this.sortForms.filter(form => form.title.includes(this.search))
        : this.sortForms;
    },
    countActiveForms() {
      return this.forms.filter(form => form.acceptingResponse).length;
    },
    countClosedForms() {
      return this.forms.filter(form => !form.acceptingResponse).length;
    }
  },
  validations: {
    formNameModal: {
      formName: {
        required
      }
    },
    formDuplicateNameModal: {
      formName: {
        required
      }
    }
  },
  methods: {
    ...mapActions("form", [
      "createForm",
      "duplicateForm",
      "renameForm",
      "moveForm",
      "unarchiveForm"
    ]),

    validateState(name) {
      const { $dirty, $error } = this.$v.formNameModal[name];
      return $dirty && $error ? false : null;
    },

    validateDuplicateState(name) {
      const { $dirty, $error } = this.$v.formDuplicateNameModal[name];
      return $dirty && $error ? false : null;
    },

    openCreateFormModal() {
      if (
        (this.forms.length < 3 && this.selectedTeam.license === "FREE") ||
        this.selectedTeam.license !== "FREE"
      ) {
        this.$bvModal.show("create-form");
      }
      if (
        this.forms.length >= 3 &&
        this.selectedTeam.license === "FREE" &&
        this.reminderPlanExpired === false
      ) {
        this.$bvModal.show("survein-full");
      }
      if (
        this.reminderPlanExpired === true &&
        this.selectedTeam.license === "FREE" &&
        this.forms.length >= 3
      ) {
        this.$bvModal.show("plan-expired");
      }
    },

    openDuplicateFormModal(formId) {
      if (
        (this.forms.length < 3 && this.selectedTeam.license === "FREE") ||
        this.selectedTeam.license !== "FREE"
      ) {
        this.$bvModal.show(this.formDuplicateNameModal.id);
        this.formDuplicateNameModal.okHandler = async () => {
          this.$v.formDuplicateNameModal.$touch();
          if (this.$v.formDuplicateNameModal.$anyError) {
            return;
          }

          this.formDuplicateNameModal.isLoading = true;
          await this.duplicateForm({
            teamId: this.teamId,
            workspaceId: this.workspaceId,
            formId,
            title: this.formDuplicateNameModal.formName
          });
          this.formDuplicateNameModal.isLoading = false;
          this.$bvModal.hide(this.formDuplicateNameModal.id);
          this.$toast.success(this.$t("formDuplicated"));
          this.$emit("get-forms");
        };
        if (
          this.forms.length >= 3 &&
          this.selectedTeam.license === "FREE" &&
          this.reminderPlanExpired === false
        ) {
          this.$bvModal.show("survein-full");
        }
        if (
          this.reminderPlanExpired === true &&
          this.selectedTeam.license === "FREE" &&
          this.forms.length >= 3
        ) {
          this.$bvModal.show("plan-expired");
        }
      }
    },

    async formRename() {
      try {
        await this.renameForm({
          teamId: this.teamId,
          workspaceId: this.workspaceId,
          formId: this.updateFormName.formId,
          title: this.updateFormName.title
        });
        this.$bvModal.hide("rename-form");
        this.updateFormName.title = "";
        this.updateFormName.formId = null;
        this.$toast.success(this.$t("formRenamed"));
      } catch (error) {
        throw new Error(error);
      }
    },

    async formMove(event) {
      const workspaceName = event.workspaceName;
      try {
        await this.moveForm({
          teamId: this.teamId,
          workspaceId: this.workspaceId,
          formId: event.formId,
          newWorkspaceId: event.workspaceId
        });
        this.$emit("get-forms");
        this.$toast.success(this.$t("formMoved", { workspaceName }));
      } catch (error) {
        throw new Error(error);
      }
    },

    async restoreForm(event) {
      const workspaceName = event.workspaceName;
      try {
        await this.unarchiveForm({
          teamId: this.teamId,
          formId: event.formId,
          newWorkspaceId: event.workspaceId
        });
        this.$emit("get-archive-forms");
        this.$toast.success(this.$t("formRestored", { workspaceName }));
      } catch (error) {
        throw new Error(error);
      }
    },

    openRenameFormModal(formId, formTitle) {
      this.$bvModal.show("rename-form");
      this.updateFormName.formId = formId;
      this.updateFormName.title = formTitle;
    },

    openUpgradeModal() {
      this.$bvModal.hide("survein-full");
      this.$bvModal.show("subscription-upgrade");
    },

    openRenewModal(isNext) {
      this.$bvModal.hide("plan-expired");
      this.$refs.renewModal.showRenewModal(isNext);
    },

    openFormNameModal(formType) {
      this.$bvModal.show(this.formNameModal.id);
      this.formNameModal.okHandler = async () => {
        this.$v.formNameModal.$touch();
        if (this.$v.formNameModal.$anyError) {
          return;
        }

        this.formNameModal.isLoading = true;

        try {
          await this.createForm({
            teamId: this.teamId,
            workspaceId: this.workspaceId,
            title: this.formNameModal.formName,
            template: formType
          });
          this.formNameModal.isLoading = false;
          this.$bvModal.hide(this.formNameModal.id);

          this.$router.push({
            name:
              formType === this.formTypeScratch
                ? "FormBuilder"
                : "FormBuilderQuiz",
            params: {
              teamId: this.form.teamId,
              workspaceId: this.form.workspaceId,
              formId: this.form.id
            }
          });
        } catch (error) {
          this.$toast.error(this.$t("generalError"));
        }
      };
    },

    onFormNameModalHide() {
      this.$v.$reset();
      this.formNameModal.formName = "";
    },

    onFormDuplicateNameModalHide() {
      this.$v.$reset();
      this.formDuplicateNameModal.formName = "";
    },

    goToForm(formId, formTemplate) {
      this.$emit("go-to-form", formId, formTemplate);
    },

    deleteForm(formId) {
      this.$emit("delete-form", formId);
    },

    archiveForm(formId) {
      this.$emit("archive-form", formId);
    },

    setActiveTab(tab) {
      this.activeTab = tab;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  width: 206px;
  height: 206px;
  border-radius: 8px;
}

.form-title {
  font-size: 16px;
  font-weight: bold;
}

.image-container {
  height: 154px;
  background: #b9d4f3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
}

.card-field {
  width: 174px;
  background: #d0e2f7;
  padding: 22px;
  display: flex;
  flex-direction: column;
  font-size: 9px;
}

.field-title {
  font-size: 9px;
  font-weight: 700;
  text-align: center;
}

.field-description {
  font-size: 4px;
  text-align: center;
}

.start-page {
  background: #3881d7;
  font-size: 4px;
  color: white;
  padding: 1px 8px;
  margin-top: 4px;
  margin: auto;
}

.short-text {
  width: 80px;
  height: 8px;
  border: 1px solid black;
  background: white;
  margin: auto;
}

.create-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tab-select {
  display: inline-block;
  padding: 10px 16px;
  cursor: pointer;

  &.active {
    border-bottom: 4px solid #166ed8;
  }
}

.alert-archive {
  margin-bottom: 0px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 44px;
}
</style>
