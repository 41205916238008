<template>
  <div class="h-100">
    <section class="h-100 d-flex flex-column">
      <ListGroupItemPadding class="mt-2">
        <div class="d-flex justify-content-between">
          <span class="font-weight-bold">{{ selectedTeamName }}</span>
          <span class="float-right add-workspace-btn">
            <b-dropdown variant="link" right no-caret>
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <b-dropdown-header
                style="width: 240px;"
                v-if="filteredTeams.length"
              >
                {{ $t("switchTo") }}
              </b-dropdown-header>
              <b-dropdown-item
                v-for="(team, index) in filteredTeams"
                :key="index"
                @click.prevent="changeTeam(team)"
                >{{ team.name }}</b-dropdown-item
              >
              <b-dropdown-divider
                v-if="filteredTeams.length"
              ></b-dropdown-divider>
              <b-dropdown-item v-b-modal.create-team-sidebar>{{
                $t("createNewTeam")
              }}</b-dropdown-item>
              <b-dropdown-item>{{ $t("editTeamMember") }}</b-dropdown-item>
              <b-dropdown-item v-if="!isOwner" @click="openRemoveMemberModal">{{
                $t("removeMe")
              }}</b-dropdown-item>
            </b-dropdown>
          </span>
        </div>
        <div class="members">
          <b-avatar
            v-for="(member, index) in avatarMember"
            :key="'count' + index"
            variant="primary"
            size="2em"
            :text="member.username ? member.username.slice(0, 2) : 'SV'"
            class="mr-2 mt-2"
          ></b-avatar>
          <b-avatar
            v-if="remainingAvatarMember"
            :text="'+' + remainingAvatarMember"
            class="mr-2 mt-2"
            size="2em"
            variant="primary"
          ></b-avatar>
          <icon
            class="mr-2 mt-2"
            v-for="(member, index) in remainingMembers"
            :key="index"
            name="no-member"
          ></icon>
        </div>
      </ListGroupItemPadding>
      <ListGroupItemPadding
        class="align-items-center d-flex justify-content-between"
      >
        <span class="font-weight-bold">{{ $t("workspace") }}</span>
        <span class="float-right add-workspace-btn">
          <b-button variant="link" :disabled="disableWorkspaceButton">
            <b-icon v-b-modal.create-new-workspace icon="plus-circle"></b-icon>
          </b-button>
        </span>
      </ListGroupItemPadding>
      <b-list-group class="flex-grow-1">
        <WorkspaceSidebarItem
          v-for="workspace of sortWorkspaces"
          :key="workspace.id"
          :active="workspace.id === selectedWorkspaceId && !isArchiveSelected"
          :id="workspace.id"
          @selected-workspace="setSelectedWorkspaceId(workspace.id)"
        >
          <span class="workspace-name">{{ workspace.name }}</span>
          <b-dropdown variant="link" right no-caret>
            <template #button-content>
              <b-icon-three-dots-vertical />
            </template>
            <b-dropdown-item @click="showRenameModal(workspace.id)">{{
              $t("rename")
            }}</b-dropdown-item>
            <b-dropdown-item
              @click="showDeleteModal(workspace.id, workspace.name)"
              >{{ $t("delete") }}</b-dropdown-item
            >
          </b-dropdown>
        </WorkspaceSidebarItem>
      </b-list-group>
      <div class="alert-wrapper">
        <div
          v-if="
            !hideAlert &&
              (reminderExpiredWeekBefore ||
                reminderPlanExpired ||
                reminderExpiredToday ||
                isFreePlan)
          "
          id="alert-toast"
          static
          no-auto-hide
          :class="
            reminderPlanExpired || reminderFreePlanLimit
              ? 'alert-custom-danger'
              : 'alert-custom-primary'
          "
        >
          <div class="d-flex flex-row justify-content-between">
            <b-icon
              font-scale="1.5"
              icon="exclamation-circle-fill"
              :variant="
                reminderPlanExpired || reminderFreePlanLimit
                  ? 'danger'
                  : 'primary'
              "
            />
            <div
              v-if="!reminderPlanExpired && !reminderFreePlanLimit"
              @click="closeAlert()"
            >
              <icon class="cursor-pointer" name="close-btn" />
            </div>
          </div>
          <span v-if="reminderExpiredMonthBefore" class="mt-2">
            {{ $t("reminderMonth", { validUntil }) }}
            <b-link v-b-modal.subscription-upgrade>{{
              $t("renewPlan")
            }}</b-link>
          </span>
          <span v-if="reminderExpiredWeekBefore" class="mt-2">
            {{ $t("reminderWeek", { validUntil }) }}
            <b-link v-b-modal.subscription-upgrade>{{
              $t("renewPlan")
            }}</b-link>
          </span>
          <span v-if="reminderExpiredToday" class="mt-2">
            {{ $t("reminderToday") }}
            <b-link v-b-modal.subscription-upgrade>{{
              $t("renewPlan")
            }}</b-link>
          </span>
          <span v-if="reminderPlanExpired" class="mt-2">
            {{ $t("reminderPlanExpired") }}
            <b-link v-b-modal.subscription-upgrade>{{
              $t("renewPlan")
            }}</b-link>
          </span>
          <span v-if="isFreePlan && !reminderFreePlanLimit" class="mt-2">
            {{ $t("reminderFreePlan") }}
            <b-link v-b-modal.subscription-upgrade v-html="$t('seePlan')" />
          </span>
          <span v-if="reminderFreePlanLimit && isFreePlan" class="mt-2">
            {{ $t("reminderFreePlanLimit") }}
            <b-link v-b-modal.subscription-upgrade v-html="$t('seePlan')" />
          </span>
        </div>
      </div>
      <b-list-group class="mt-auto border-top">
        <b-list-group-item @click="showArchiveForms" class="border-0">
          <div class="d-flex justify-content-between align-items-center">
            <span style="font-weight: bold">{{ $t("more") }}</span>
            <b-button style="color: #5E6674" variant="link">
              <b-icon
                :icon="archiveVisible ? 'chevron-down' : 'chevron-up'"
              ></b-icon>
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-collapse id="archive-collapse" v-model="archiveVisible">
        <div
          @click="setArchiveSelected"
          :style="
            isArchiveSelected
              ? 'background: #edf5fd; color: #166ed8;'
              : 'color: #5E6674;'
          "
          class="recently-deleted"
        >
          <b-icon icon="trash"></b-icon>
          <span class="ml-3">{{ $t("recentlyDeleted") }}</span>
        </div>
      </b-collapse>
    </section>

    <b-modal no-stacking centered id="create-new-workspace">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("createWorkspace") }}</h5>
      </template>
      <template #default>
        <b-form-group :label="$t('workspaceName')">
          <b-form-input
            v-model="workspaceName"
            type="text"
            :placeholder="$t('placeholderWorkspace')"
          />
        </b-form-group>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button size="md" variant="primary" @click="addNewWorkspace">
          {{ $t("create") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-stacking centered id="rename-workspace">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("renameWorkspace") }}</h5>
      </template>
      <template #default>
        <b-form-group :label="$t('newWorkspaceName')">
          <b-form-input
            v-model="workspaceName"
            type="text"
            :placeholder="$t('placeholderWorkspace')"
          />
        </b-form-group>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button size="md" variant="primary" @click="setWorkspaceName">
          {{ $t("rename") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-stacking centered id="confirm-delete">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("deleteWorkspace") }}</h5>
      </template>
      <template #default>
        <b-form-group :label="$t('confirmDeleteTitle')">
          <b-form-input
            v-model="deleteConfirm"
            type="text"
            :placeholder="$t('confirmDeletePlaceholder')"
            @change="deleteConfirmErr = false"
          />
          <small class="text-danger" v-if="deleteConfirmErr">{{
            $t("confirmDeleteErrMsg")
          }}</small>
        </b-form-group>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button size="md" variant="primary" @click="deleteUserWorkspace()">
          {{ $t("delete") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-stacking centered id="delete-workspace">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("deleteWorkspace") }}</h5>
      </template>
      <template #default>
        <span class="text-center"
          ><icon name="delete" /><br />
          <div class="text-center">
            {{ $t("deleteWorkspaceDesc", { workspaceName }) }}
          </div></span
        >
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button size="md" variant="danger" @click="openConfirmDeleteModal()">
          {{ $t("delete") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL CREATE TEAM -->
    <b-modal centered id="create-team-sidebar" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("createNewTeam") }}</h5>
      </template>
      <template #default>
        <b-form>
          <b-form-group :label="$t('teamName')">
            <b-form-input
              :placeholder="$t('teamNamePlaceholder')"
              :state="$v.newTeam.name.required ? null : false"
              v-model="newTeam.name"
              type="text"
            ></b-form-input>
            <div class="error" v-if="!$v.newTeam.name.required">
              {{ $t("teamNameError") }}
            </div>
          </b-form-group>
          <b-form-group :label="$t('teamDesc')">
            <b-form-textarea
              :placeholder="$t('teamDescPlaceholder')"
              v-model="newTeam.description"
              :state="$v.newTeam.description.maxLength ? null : false"
              type="text"
            ></b-form-textarea>
            <div class="error" v-if="!$v.newTeam.description.maxLength">
              {{ $t("teamDescError") }}
            </div>
          </b-form-group>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="teamCreate">
          {{ $t("create") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL REMOVE ME FROM TEAM-->
    <b-modal centered id="remove-member-sidebar" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("removeMeFromTeam") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("removeMeFromTeamDesc") }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button @click="deleteMember" variant="primary">
          {{ $t("removeMyself") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<i18n>
{
  "en": {
    "workspace": "Workspace",
    "reminderMonth": "Your plan will be expired on {validUntil}. You can do early payment to extend its validity for keep using Survein.",
    "reminderWeek": "Your plan will be expired on {validUntil}. You can do early payment to extend its validity for keep using Survein.",
    "reminderToday": "Your plan will be expired on today. You can do early payment to extend its validity for keep using Survein.",
    "reminderPlanExpired": "Some features are not working due to an expired plan. To reactivate, you can renew your plan.",
    "reminderFreePlan": "You are using Survein FREE plan. Which you got 3 forms and 50 responses. Upgrade for more fancy features.",
    "reminderFreePlanLimit": "You’ve just reached the plan limit. Upgrade for unlimited forms and unlimited responses, and more features.",
    "seePlan": "See plan",
    "renewPlan": "Renew plan",
    "createWorkspace": "Create Workspace",
    "placeholderWorkspace": "Eg: Personal workspace, or Working workspace",
    "workspaceName": "Workspace Name",
    "create": "Create",
    "cancel": "Cancel",
    "rename": "Rename",
    "addEditors": "Add Editors",
    "duplicate": "Duplicate",
    "delete": "Delete",
    "renameWorkspace": "Rename Workspace",
    "newWorkspaceName": "Enter your new Workspace name",
    "workspaceAdded": "Workspace Added",
    "workspaceRenamed": "Workspace Renamed",
    "deleteWorkspace": "Are you sure want to delete Workspace?",
    "deleteWorkspaceDesc": "Deleting workspace {workspaceName} will also delete your forms and responses. are you sure?",
    "workspaceDeleted": "Workspace Deleted",
    "confirmDeleteTitle": "Please type 'permanently delete' to delete the workspace",
    "confirmDeletePlaceholder": "permanently delete",
    "confirmDeleteErrMsg": "Please type the words correctly",
    "createNewTeam": "Create new team",
    "editTeamMember": "Edit team members",
    "removeMe" : "Remove me from this team",
    "switchTo": "Switch to",
    "more": "More",
    "recentlyDeleted": "Recently deleted",
    "teamName": "Team name",
    "teamDesc": "Team description",
    "teamNamePlaceholder": "Eg: Marketing, Product, or Operation",
    "teamDescPlaceholder": "Brief description about the team",
    "teamNameError": "Team name required",
    "teamDescError": "Maximum 80 Character",
    "create": "Create",
    "createNewTeam": "Create new team",
    "teamCreated": "Team created",
    "removeMeFromTeam": "Remove Me from the Team",
    "removeMeFromTeamDesc": "You will no longer be able to contribute in this team once you remove yourself. Are you sure want to do it?",
    "removeMyself": "Remove myself",
    "errorRemoveMember": "An error occurred while removing the member",
    "removeMemberSuccess": "Member removed"
  },
  "id": {
    "workspace": "Ruang kerja",
    "reminderMonth": "Paket Anda akan kedaluwarsa pada {validUntil}. Anda bisa melakukan pembayaran lebih awal untuk memperpanjang masa berlakunya.",
    "reminderWeek": "Paket Anda akan kedaluwarsa pada {validUntil}. Anda bisa melakukan pembayaran lebih awal untuk memperpanjang masa berlakunya.",
    "reminderToday": "Paket Anda akan kedaluwarsa pada hari ini. Anda bisa melakukan pembayaran lebih awal untuk memperpanjang masa berlakunya.",
    "reminderPlanExpired": "Beberapa fitur tidak berfungsi karena paket sudah kedaluwarsa. Untuk mengaktifkan kembali, Anda dapat memperbarui paket Anda.",
    "reminderFreePlan": "Anda menggunakan paket Survein FREE. Anda mendapat 3 formulir dan 50 tanggapan. Tingkatkan untuk mendapatkan fitur yang lebih mewah.",
    "reminderFreePlanLimit": "Anda sudah mencapai batas paket. Tingkatkan untuk mendapatkan formulir dan tanggapan tak terbatas, serta lebih banyak fitur.",
    "seePlan": "Lihat paket",
    "renewPlan": "Perbarui Paket",
    "createWorkspace": "Buat Ruang Kerja",
    "placeholderWorkspace": "Cth: Ruang kerja pribadi, atau Ruang kerja kerja",
    "workspaceName": "Nama Ruang Kerja",
    "create": "Buat",
    "cancel": "Batal",
    "rename": "Ubah nama",
    "addEditors": "Tambah Editor",
    "duplicate": "Duplikat",
    "delete": "Menghapus",
    "renameWorkspace": "Ubah nama Ruang Kerja",
    "newWorkspaceName": "Masukan nama Ruang Kerja baru",
    "workspaceAdded": "Berhasil Menambah Ruang Kerja",
    "workspaceRenamed": "Berhasil Mengubah Nama Ruang Kerja",
    "deleteWorkspace": "Apa kamu yakin ingin menghapus Ruang Kerja?",
    "deleteWorkspaceDesc": "Menghapus Ruang Kerja {workspaceName} juga akan menghapus formulir dan tanggapan. apa kamu yakin?",
    "workspaceDeleted": "Ruang kerja berhasil dihapus",
    "confirmDeleteTitle": "Mohon ketik 'permanently delete' untuk menghapus workspace",
    "confirmDeletePlaceholder": "permanently delete",
    "confirmDeleteErrMsg": "Mohon ketik kata diatas dengan benar",
    "createNewTeam": "Buat tim baru",
    "editTeamMember": "Ubah anggota tim",
    "removeMe" : "Hapus saya dari team ini",
    "switchTo": "Berpindah ke",
    "more": "Lebih banyak",
    "recentlyDeleted": "Baru saja dihapus",
    "teamName": "Nama tim",
    "teamDesc": "Deskripsi tim",
    "teamNamePlaceholder": "Cth: Marketing, Product, atau Operation",
    "teamDescPlaceholder": "Deskripsi singkat tentang tim",
    "teamNameError": "Nama tim diperlukan",
    "teamDescError": "Maksimal 80 Karakter",
    "create": "Buat",
    "createNewTeam": "Buat tim baru",
    "teamCreated": "Berhasil membuat tim",
    "removeMeFromTeam": "Keluarkan Saya dari Tim",
    "removeMeFromTeamDesc": "Anda tidak dapat lagi berkontribusi dalam tim ini setelah Anda menghapus diri sendiri. Apakah Anda yakin ingin melakukannya?",
    "removeMyself": "Hapus diriku sendiri",
    "errorRemoveMember": "Terjadi kesalahan saat menghapus anggota",
    "removeMemberSuccess": "Berhasil menghapus anggota"
  }
}
</i18n>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { isToday, differenceInDays } from "date-fns";
import ListGroupItemPadding from "@/components/common/ListGroupItemPadding";
import WorkspaceSidebarItem from "@/components/Workspace/WorkspaceSidebarItem";
import { cloneDeep } from "lodash";
import { maxLength, required } from "vuelidate/lib/validators";
import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";

export default {
  components: {
    ListGroupItemPadding,
    WorkspaceSidebarItem
  },
  props: {
    forms: {
      type: Array,
      default: () => []
    },
    workspaces: {
      type: Array,
      default: () => []
    },
    selectedWorkspaceId: {
      type: String,
      default: null
    },
    teams: {
      type: Array,
      required: true
    },
    archiveSelected: {
      type: Boolean,
      required: true
    },
    authenticatedUser: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hideAlert: false,
      workspaceName: "",
      activeWorkspaceId: null,
      deleteConfirm: "",
      deleteConfirmErr: false,
      archiveVisible: false,
      isArchiveSelected: this.archiveSelected,
      newTeam: {
        name: "",
        description: ""
      }
    };
  },
  validations: {
    newTeam: {
      name: {
        required
      },
      description: {
        maxLength: maxLength(80)
      }
    }
  },
  computed: {
    ...mapGetters("team", ["selectedTeam"]),

    isFreePlan() {
      let freePlan = false;
      if (this.selectedTeam) {
        if (
          this.selectedTeam.license === "FREE" &&
          this.selectedTeam.licenseValidUntil === null
        ) {
          freePlan = true;
        }
      }
      return freePlan;
    },

    validUntil() {
      let valid = null;
      if (this.selectedTeam.licenseValidUntil !== null) {
        valid = moment(this.selectedTeam.licenseValidUntil).format(
          "DD MMM YYYY"
        );
      }
      return valid;
    },

    daysBeforeExpired() {
      let expired = null;
      if (this.selectedTeam) {
        if (
          this.selectedTeam.license !== "FREE" &&
          this.selectedTeam.licenseValidUntil !== null
        ) {
          expired = differenceInDays(
            this.selectedTeam.licenseValidUntil,
            Date.now()
          );
        }
      }
      return expired;
    },

    reminderExpiredMonthBefore() {
      let reminder = false;
      if (
        this.daysBeforeExpired === 30 &&
        this.selectedTeam.licenseDuration === "yearly"
      ) {
        reminder = true;
      }
      return reminder;
    },

    reminderExpiredWeekBefore() {
      let reminder = false;
      if (this.daysBeforeExpired === 7) {
        reminder = true;
      }
      return reminder;
    },

    reminderExpiredToday() {
      let reminder = false;
      if (this.selectedTeam) {
        reminder = isToday(new Date(this.selectedTeam.licenseValidUntil));
      }
      return reminder;
    },

    reminderPlanExpired() {
      let reminder = false;
      if (this.daysBeforeExpired < 0) {
        reminder = true;
      }
      return reminder;
    },

    reminderFreePlanLimit() {
      let reminder = false;
      if (this.forms.length >= 3) {
        reminder = true;
      }
      return reminder;
    },

    disableWorkspaceButton() {
      let isDisabled = false;

      if (this.selectedTeam) {
        if (
          (this.selectedTeam.license === "FREE" ||
            this.selectedTeam.license === "ESSENTIALS") &&
          this.workspaces.length >= 1
        ) {
          isDisabled = true;
        }

        if (
          this.selectedTeam.license === "PROFESSIONAL" &&
          this.workspaces.length >= 3
        ) {
          isDisabled = true;
        }

        if (
          this.selectedTeam.license === "PREMIUM" &&
          this.workspaces.length >= 5
        ) {
          isDisabled = true;
        }
      }

      return isDisabled;
    },

    sortWorkspaces() {
      let workspaces = cloneDeep(this.workspaces);

      workspaces = workspaces.sort((a, b) => a.name.localeCompare(b.name));

      return workspaces;
    },

    filteredTeams() {
      if (this.teams.length > 1 && this.selectedTeam) {
        return this.teams.filter(team => team.id !== this.selectedTeam.pk);
      } else {
        return [];
      }
    },

    selectedTeamName() {
      return this.selectedTeam ? this.selectedTeam.teamName : "";
    },

    remainingMembers() {
      let count;
      if (this.selectedTeam.members.length < 5) {
        count = 5 - this.selectedTeam.members.length;
      }
      return count;
    },

    avatarMember() {
      let members;
      if (this.selectedTeam.members.length > 5) {
        members = this.selectedTeam.members.slice(0, 4);
      } else {
        members = this.selectedTeam.members;
      }
      return members;
    },

    remainingAvatarMember() {
      let count;

      if (this.selectedTeam.members.length > 5) {
        count = this.selectedTeam.members.length - 4;
      }

      return count;
    },

    isOwner() {
      if (this.selectedTeam) {
        const owner = this.selectedTeam.members.find(
          member => member.role === "owner"
        );

        return (
          !!owner && owner.email === this.authenticatedUser.attributes.email
        );
      }
      return false;
    }
  },
  methods: {
    ...mapActions("profile", [
      "createWorkspace",
      "renameWorkspace",
      "deleteWorkspace",
      "createTeam",
      "getProfile"
    ]),
    ...mapActions("workspace", ["getWorkspaces"]),
    ...mapActions("team", ["getTeam"]),

    closeAlert() {
      this.hideAlert = true;
    },

    async addNewWorkspace() {
      await this.createWorkspace({
        teamId: this.selectedTeam.pk,
        workspaceName: this.workspaceName,
        vm: this
      });
      await this.getWorkspaces({ teamId: this.selectedTeam.pk });
      this.$bvModal.hide("create-new-workspace");
      this.workspaceName = "";
    },

    async setWorkspaceName() {
      await this.renameWorkspace({
        teamId: this.selectedTeam.pk,
        workspaceId: this.activeWorkspaceId,
        workspaceName: this.workspaceName,
        vm: this
      });
      await this.getWorkspaces({ teamId: this.selectedTeam.pk });
      this.$bvModal.hide("rename-workspace");
      this.workspaceName = "";
    },

    openConfirmDeleteModal() {
      this.$bvModal.hide("delete-workspace");
      this.$bvModal.show("confirm-delete");
    },

    async deleteUserWorkspace() {
      if (this.deleteConfirm !== "permanently delete") {
        this.deleteConfirmErr = true;
        return;
      }

      await this.deleteWorkspace({
        teamId: this.selectedTeam.pk,
        workspaceId: this.activeWorkspaceId,
        vm: this
      });
      await this.getWorkspaces({ teamId: this.selectedTeam.pk });
      this.$bvModal.hide("confirm-delete");
      this.workspaceName = "";
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$emit("delete-workspace");
        });
      });
      this.deleteConfirm = "";
    },

    async teamCreate() {
      await this.createTeam({
        username: this.authenticatedUser.attributes.nickname,
        teamName: this.newTeam.name,
        teamDescription: this.newTeam.description,
        email: this.authenticatedUser.attributes.email,
        vm: this
      });
      this.$bvModal.hide("create-team-sidebar");
    },

    async deleteMember() {
      try {
        const email = this.authenticatedUser.attributes.email;
        const body = { email };

        await API.post(
          API_NAME,
          `/teams/${this.selectedTeam.pk}/remove-member`,
          { body }
        );

        await this.getProfile();
        if (this.teams.length) {
          await this.getTeam({ teamId: this.teams[0].id });
        }

        this.$toast.success(this.$t("removeMemberSuccess"));
        this.$bvModal.hide("remove-member-sidebar");
      } catch (err) {
        this.$toast.warning(this.$t("errorRemoveMember"));
      }
    },

    openRemoveMemberModal() {
      this.$bvModal.show("remove-member-sidebar");
    },

    setSelectedWorkspaceId(id) {
      this.isArchiveSelected = false;
      this.$emit("selected-workspace", id);
    },

    showRenameModal(id) {
      this.$bvModal.show("rename-workspace");
      this.activeWorkspaceId = id;
    },

    showDeleteModal(id, name) {
      this.$bvModal.show("delete-workspace");
      this.activeWorkspaceId = id;
      this.workspaceName = name;
    },

    showArchiveForms() {
      this.archiveVisible = !this.archiveVisible;
      if (this.archiveVisible === false) {
        this.isArchiveSelected = false;
      }
    },

    setArchiveSelected() {
      this.isArchiveSelected = !this.isArchiveSelected;
      this.$emit("update:archive-selected", this.isArchiveSelected);
    },

    changeTeam(team) {
      this.$emit("active-team", {
        id: team.id,
        name: team.name,
        workspaces: team.workspaces
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.add-workspace-btn {
  cursor: pointer;
  &:hover {
    color: #007bff;
  }
}

.alert-wrapper {
  padding: 20px;
  height: auto;
}

.alert-custom-primary {
  border: 0.5px solid #166ed8;
  background: #e8f1fb;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.alert-custom-danger {
  border: 0.5px solid #eb5757;
  background: #feecea;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.close-btn {
  cursor: pointer;
}

.workspace-name {
  max-width: calc(100% - 40px);
}

.recently-deleted {
  padding: 8px 24px;
  height: 36px;
  margin-bottom: 16px;
  cursor: pointer;
}

.error {
  color: #f7195a;
  font-size: 14px;
  margin-top: 4px;
}

.members {
  display: flex;
  flex-wrap: wrap;
  margin-right: 8px;
}
</style>
