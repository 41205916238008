<template>
  <b-list-group-item
    @click="setActiveWorkspace"
    v-bind="$attrs"
    button
    class="d-flex justify-content-between align-items-center border-0"
  >
    <slot />
  </b-list-group-item>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

.list-group-item.active {
  background-color: #e6f7ff;
  color: $primary;
}
</style>

<script>
export default {
  props: {
    id: {
      type: String
    }
  },
  methods: {
    setActiveWorkspace() {
      this.$emit("selected-workspace", this.id);
    }
  }
};
</script>
