<template>
  <div class="h-100">
    <SidebarMainLayout v-if="hasTeam">
      <template #sidebar>
        <WorkspaceSidebar
          v-if="selectedTeam"
          :forms="forms"
          :workspaces="workspaces"
          :teams="teams"
          :selectedWorkspaceId="activeWorkspaceId"
          :archiveSelected.sync="archiveSelected"
          :authenticatedUser="authenticatedUser"
          @selected-workspace="setActiveWorkspaceId"
          @delete-workspace="selectFirstWorkspace()"
          @active-team="selectActiveTeam($event)"
        />
      </template>
      <WorkspaceMain
        v-if="hasTeam"
        :forms="filteredForms"
        :teamId="selectedTeam ? selectedTeam.pk : teams[0].id"
        :workspaceId="activeWorkspaceId"
        :workspaces="workspaces"
        :archive-selected="archiveSelected"
        @go-to-form="goToForm"
        @delete-form="showDeleteFormModal"
        @archive-form="showArchiveFormModal"
        @get-forms="getWorkspaceForms"
        @get-archive-forms="listArchive"
      />
      <b-modal centered id="delete-form">
        <template #modal-header>
          <h5 class="mb-0 col-12 text-center">
            {{ $t("deleteFormPermanent") }}
          </h5>
        </template>
        <template #default>
          <icon name="delete" />
          <div class="text-center">{{ $t("confirmDeletePermanent") }}</div>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button size="md" variant="light" @click="cancel()">
            {{ $t("cancel") }}
          </b-button>
          <b-button size="md" variant="danger" @click="formDelete">
            {{ $t("deleteForm") }}
          </b-button>
        </template>
      </b-modal>

      <b-modal centered id="archive-form">
        <template #modal-header>
          <h5 class="mb-0 col-12 text-center">{{ $t("deleteForm") }}</h5>
        </template>
        <template #default>
          <span><icon name="delete"/></span>
          <div class="text-center">{{ $t("confirmDel") }}</div>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button size="md" variant="light" @click="cancel()">
            {{ $t("cancel") }}
          </b-button>
          <b-button size="md" variant="danger" @click="formArchive">
            {{ $t("deleteForm") }}
          </b-button>
        </template>
      </b-modal>
    </SidebarMainLayout>

    <!-- NO TEAM TEMPLATE -->
    <div v-if="!hasTeam" class="create-first-team">
      <icon name="create-form"></icon>
      <h4 class="m-0">{{ $t("createFirstTeam") }}</h4>
      <b-button class="mt-3" variant="primary" v-b-modal.create-team>{{
        $t("createTeam")
      }}</b-button>
    </div>

    <!-- MODAL CREATE WORKSPACE AND TEAM -->

    <b-modal
      no-close-on-backdrop
      no-stacking
      no-close-on-esc
      centered
      id="welcome"
    >
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("welcomeToSurvein") }}</h5>
      </template>
      <template #default>
        <icon name="welcome-survein"></icon>
        <p class="description-text" v-html="$t('setupEnv')" />
      </template>
      <template #modal-footer>
        <b-button size="md" variant="primary" v-b-modal.create-team>
          {{ $t("letsgo") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop centered id="create-team" no-stacking>
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("createTeam") }}</h5>
      </template>
      <template #default>
        <icon name="create-team"></icon>
        <p class="description-text">
          {{ $t("createTeamDesc") }}
        </p>
      </template>
      <template #modal-footer>
        <b-button size="md" variant="primary" v-b-modal.create-team2>
          {{ $t("next") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop no-stacking centered id="create-team2">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("createTeam") }}</h5>
      </template>
      <template #default>
        <b-form-group :label="$t('teamName')">
          <b-form-input
            v-model="form.teamName"
            type="text"
            :placeholder="$t('placeholderTeam')"
          />
        </b-form-group>
      </template>
      <template #modal-footer>
        <b-button size="md" variant="primary" v-b-modal.create-workspace>
          {{ $t("next") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop no-stacking centered id="create-workspace">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("createWorkspace") }}</h5>
      </template>
      <template #default>
        <icon name="create-workspace"></icon>
        <p class="description-text">
          {{ $t("createWorkspaceDesc") }}
        </p>
      </template>
      <template #modal-footer>
        <b-button size="md" variant="primary" v-b-modal.create-workspace2>
          {{ $t("next") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop no-stacking centered id="create-workspace2">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("createWorkspace") }}</h5>
      </template>
      <template #default>
        <b-form-group :label="$t('workspaceName')">
          <b-form-input
            v-model="form.workspaceName"
            type="text"
            :placeholder="$t('placeholderWorkspace')"
          />
        </b-form-group>
      </template>
      <template #modal-footer>
        <b-button size="md" variant="primary" v-b-modal.finished>
          {{ $t("next") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal no-close-on-backdrop no-stacking centered id="finished">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("setupFinished") }}</h5>
      </template>
      <template #default>
        <icon name="publish-success"></icon>
        <p class="description-text" v-html="$t('setupFinishDesc')" />
      </template>
      <template #modal-footer>
        <b-button size="md" variant="primary" @click="onSubmit()">
          {{ $t("finish") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL GETTING INVITATIONS -->
    <b-modal no-close-on-backdrop no-stacking centered id="invitations">
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("teamInvitation") }}</h5>
      </template>
      <template #default>
        <icon name="team-invitation"></icon>
        <p
          class="description-text"
          v-html="$t('gettingInvited', { teamName: invitations[0].teamName })"
        />
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="light" @click="cancel()">
          {{ $t("later") }}
        </b-button>
        <b-button size="md" variant="primary" @click="acceptInvitation()">
          {{ $t("accept") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<i18n>
{
  "en": {
    "responses": "Responses",
    "edit": "Edit",
    "duplicate": "Duplicate",
    "delete": "Delete",
    "deleteForm": "Delete Form",
    "formDeleted": "Successfully delete the form",
    "confirmDel": "Are you sure want to delete this form?",
    "cancel": "Cancel",
    "ok": "OK",
    "welcomeToSurvein": "Welcome to Survein!",
    "setupEnv": "We glad to have you here. Before start using Survein, <br /> let’s set up the environment.",
    "letsgo": "Lets go!",
    "createTeam": "Create Team",
    "createTeamDesc": "A team name can help you to organize team members, and make workspaces collaboration more efficient.",
    "next": "Next",
    "createWorkspace": "Create Workspace",
    "createWorkspaceDesc": "Great! Team has been created. Now, to avoid your forms cluttered, a workspace can help you group and organize all them.",
    "setupFinished": "Set Up Finished!",
    "setupFinishDesc": "Wohoo! Everything has been setup. <br />Now your account is ready to use Survein!",
    "finish": "Finish",
    "placeholderTeam": "Eg: Sales Team, Marketing Team, or Product Team",
    "placeholderWorkspace": "Eg: Personal workspace, or Working workspace",
    "teamName": "Team Name",
    "workspaceName": "Workspace Name",
    "deleteFormPermanent": "Delete Form Permanently",
    "confirmDeletePermanent": "All corresponding data in this form will be deleted permanently. Are you sure want to delete?",
    "formDeletedPermanent": "Form is deleted permanently",
    "createFirstTeam": "Start create your first team",
    "createTeam": "Create team",
    "teamInvitation": "Team invitation",
    "gettingInvited": "You are getting invited to collaborate with {teamName} team",
    "accept": "Accept",
    "later": "Later",
    "firstSetupSuccess": "Team and workspace created",
    "successAcceptInvitation": "Invitation accepted",
    "errorAcceptInvitation": "An error occurred while accepting the invitation",
    "errorGetInvitation": "An error occurred while getting the invitation"
  },
  "id": {
    "responses": "Tanggapan",
    "edit": "Edit",
    "duplicate": "Duplikat",
    "delete": "Menghapus",
    "deleteForm": "Hapus Formulir",
    "formDeleted": "Berhasil menghapus formulir",
    "confirmDel": "Apakah kamu yakin untuk menghapus formulir ini?",
    "cancel": "Batal",
    "ok": "OK",
    "welcomeToSurvein": "Selamat datang di Survein!",
    "setupEnv": "Kami senang Anda ada di sini. Sebelum mulai <br/> menggunakan Survein, mari kita siapkan environment",
    "letsgo": "Ayo!",
    "createTeam": "Buat Tim",
    "createTeamDesc": "Nama tim dapat membantu Anda mengatur anggota tim, dan membuat kolaborasi ruang kerja lebih efisien.",
    "next": "Selanjutnya",
    "createWorkspace": "Buat Ruang Kerja",
    "createWorkspaceDesc": "Bagus! Tim telah dibuat. Sekarang, untuk menghindari formulir Anda berantakan, ruang kerja dapat membantu Anda mengelompokkan dan mengatur semuanya.",
    "setupFinished": "Set Up Selesai!",
    "setupFinishDesc": "Wohoo! Semuanya sudah diatur. <br /> Sekarang akun Anda siap menggunakan Survein!",
    "finish": "Selesai",
    "placeholderTeam": "Cth: Tim Penjualan, Tim Pemasaran, atau Tim Produk",
    "placeholderWorkspace": "Cth: Ruang kerja pribadi, atau Ruang kerja kerja",
    "teamName": "Nama Tim",
    "workspaceName": "Nama Ruang Kerja",
    "deleteFormPermanent": "Hapus Formulir Secara Permanen",
    "confirmDeletePermanent": "Semua data terkait dalam formulir ini akan dihapus secara permanen. Anda yakin ingin menghapus?",
    "formDeletedPermanent": "Formulir dihapus secara permanen",
    "createFirstTeam": "Mulai buat tim pertama anda",
    "createTeam": "Buat tim",
    "teamInvitation": "Undangan bergabung tim",
    "gettingInvited": "Anda telah diundang untuk berkolaborasi dengan {teamName} tim",
    "accept": "Terima",
    "later": "Nanti",
    "firstSetupSuccess": "Berhasil membuat tim dan ruang kerja",
    "successAcceptInvitation": "Undangan diterima",
    "errorAcceptInvitation": "Terjadi kesalahan saat menerima undangan",
    "errorGetInvitation": "Terjadi kesalahan saat menerima undangan"
  }
}
</i18n>

<script>
import { mapActions, mapGetters } from "vuex";
import WorkspaceSidebar from "@/components/Workspace/WorkspaceSidebar";
import WorkspaceMain from "@/components/Workspace/WorkspaceMain";
import SidebarMainLayout from "@/layouts/SidebarMainLayout";
import { cloneDeep } from "lodash";
import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";

export default {
  components: {
    WorkspaceSidebar,
    WorkspaceMain,
    SidebarMainLayout
  },
  computed: {
    ...mapGetters("profile", ["teams"]),
    ...mapGetters("workspace", ["selectedWorkspaceId", "workspaces"]),
    ...mapGetters("team", ["selectedTeam"]),
    ...mapGetters("auth", ["authenticatedUser"]),
    ...mapGetters("form", ["forms", "archiveForms"]),

    filteredForms() {
      const forms = cloneDeep(
        !this.archiveSelected ? this.forms : this.archiveForms
      );

      if (forms.length > 0) {
        for (const form of forms) {
          const findDuplicateForms = forms.filter(
            arrItem => arrItem.id === form.id
          );
          if (findDuplicateForms.length > 1) {
            const draftVersion = findDuplicateForms.find(
              item => item.version === "DRAFT"
            );
            forms.splice(forms.indexOf(draftVersion), 1);
          }
        }
      }

      return forms;
    },
    hasTeam() {
      return !!this.teams.length;
    }
  },
  async mounted() {
    await this.getProfile();
    await this.getInvitations();

    if (this.invitations.length) {
      this.$bvModal.show("invitations");
    }

    this.$nextTick(() => {
      this.$nextTick(async () => {
        if (this.hasTeam) {
          {
            if (!this.selectedTeam) {
              await this.getTeam({
                teamId: this.teams[0].id
              });
              await this.getWorkspaces({
                teamId: this.teams[0].id
              });
            }
            if (this.workspaces.length) {
              this.activeWorkspaceId = this.workspaces[0].id;
              await this.listArchive();
            }
          }
        }
      });
    });
  },
  data() {
    return {
      isLoading: false,
      selectedFormId: "",
      form: {
        username: "John Doe",
        teamName: "",
        workspaceName: ""
      },
      activeWorkspaceId: "",
      archiveSelected: false,
      invitations: []
    };
  },
  methods: {
    ...mapActions("workspace", [
      "setSelectedWorkspaceId",
      "getForms",
      "listArchiveForm",
      "deleteForm",
      "getWorkspaces"
    ]),

    ...mapActions("team", ["getTeam"]),
    ...mapActions("profile", ["createProfile", "getProfile", "firstSetup"]),
    ...mapActions("form", ["archiveForm", "setForms"]),
    ...mapActions("auth", ["signOut"]),

    goToForm(formId, formTemplate) {
      if (formTemplate === "quiz") {
        this.$router.push({
          name: "FormBuilderQuiz",
          params: {
            teamId: this.selectedTeam.pk,
            workspaceId: this.activeWorkspaceId,
            formId
          }
        });
      } else {
        this.$router.push({
          name: "FormBuilder",
          params: {
            teamId: this.selectedTeam.pk,
            workspaceId: this.activeWorkspaceId,
            formId
          }
        });
      }
    },
    showDeleteFormModal(formId) {
      this.selectedFormId = formId;
      this.$bvModal.show("delete-form");
    },
    showArchiveFormModal(formId) {
      this.selectedFormId = formId;
      this.$bvModal.show("archive-form");
    },
    async formDelete() {
      await this.deleteForm({
        teamId: this.selectedTeam.pk,
        workspaceId: this.activeWorkspaceId,
        formId: this.selectedFormId,
        vm: this
      });
      await this.listArchive();
      this.$bvModal.hide("delete-form");
    },
    async formArchive() {
      await this.archiveForm({
        teamId: this.selectedTeam.pk,
        workspaceId: this.activeWorkspaceId,
        formId: this.selectedFormId,
        vm: this
      });
      this.getWorkspaceForms();
      this.$bvModal.hide("archive-form");
    },
    async onSubmit() {
      this.isLoading = true;
      await this.firstSetup({
        teamName: this.form.teamName,
        workspaceName: this.form.workspaceName,
        vm: this
      });
      this.$bvModal.hide("finished");
      await this.getProfile();
      await this.getTeam({ teamId: this.teams[0].id });
      this.isLoading = false;
    },
    async getWorkspaceForms() {
      await this.getForms({
        teamId: this.selectedTeam.pk,
        workspaceId: this.activeWorkspaceId
      });
    },
    setActiveWorkspaceId(id) {
      this.archiveSelected = false;
      this.activeWorkspaceId = id;
    },
    selectFirstWorkspace() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.workspaces.length) {
            this.activeWorkspaceId = this.workspaces[0].id;
          }
        });
      });
    },
    async listArchive() {
      await this.listArchiveForm({
        teamId: this.selectedTeam.pk
      });
    },
    async selectActiveTeam(team) {
      await this.getTeam({ teamId: team.id });
    },
    async getInvitations() {
      try {
        const invitations = await API.get(
          API_NAME,
          `/teams/list/${this.authenticatedUser.attributes.email}/get-invitations`
        );
        this.invitations = invitations;
      } catch (err) {
        this.$toast.warning(this.$t("errorGetInvitation"));
      }
    },
    async acceptInvitation() {
      try {
        await API.post(API_NAME, `/invite/${this.invitations[0].pk}/accept`);
        await this.getProfile();
        await this.getTeam({ teamId: this.teams[0].id });
        this.$bvModal.hide("invitations");
        this.$toast.success(this.$t("successAcceptInvitation"));
      } catch (err) {
        this.$toast.warning(this.$t("errorAcceptInvitation"));
      }
    }
  },
  watch: {
    activeWorkspaceId: {
      immediate: true,
      handler() {
        this.setForms([]);
        if (this.workspaces.length) {
          this.getWorkspaceForms();
        }
      }
    },
    selectedTeam: {
      immediate: true,
      deep: true,
      async handler() {
        this.archiveSelected = false;
        this.setForms([]);
        if (this.selectedTeam && this.teams.length) {
          await this.getWorkspaces({ teamId: this.selectedTeam.pk });
        }
      }
    },
    workspaces: {
      immediate: true,
      deep: true,
      handler() {
        if (this.workspaces && this.workspaces.length) {
          this.activeWorkspaceId = this.workspaces[
            this.workspaces.length - 1
          ].id;
        }
      }
    },
    archiveSelected: {
      immediate: true,
      deep: true,
      handler() {
        if (this.archiveSelected) {
          this.listArchive();
        }
      }
    },
    authenticatedUser: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.authenticatedUser) {
          this.signOut();
        }
      }
    }
  }
};
</script>

<style scoped>
.description-text {
  color: rgba(94, 102, 116, 1);
  text-align: center;
  margin: auto;
}

.create-first-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
