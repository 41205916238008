<template>
  <div class="card-shadow">
    <b-card no-body class="cursor-pointer card-border mb-3" @click="goToForm">
      <div class="card__image-container position-relative">
        <div
          v-if="!form.acceptingResponse && !archiveSelected"
          class="badge-custom"
        >
          {{ $t("formClosed") }}
        </div>
        <div v-if="archiveSelected" class="badge-custom-archive">
          {{ archiveDate }} day
        </div>
        <div
          class="card__image-bg h-100 w-100 overflow-hidden"
          v-if="form.fields && form.fields.length"
        >
          <div
            class="card-field mb-1"
            v-for="field in fieldsFilter"
            :key="field.id"
          >
            <span class="field-title">{{ field.title }}</span>
            <span class="field-description" v-html="field.description"></span>
            <div
              class="start-page mt-1"
              v-if="field.type === 'START_PAGE' && field.btnText"
            >
              {{ field.btnText }}
            </div>
            <div class="short-text mt-1" v-if="field.type === 'SHORT_TEXT'" />
            <div
              v-if="field.type === 'EMAIL'"
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <b-icon icon="envelope-fill" font-scale="0.75" />

              <div class="email ml-1"></div>
            </div>
            <div v-if="field.type === 'LONG_TEXT'" class="long-text mt-1"></div>
            <div v-if="field.type === 'MULTISELECT'">
              <div class="d-flex flex-row justify-content-center">
                <div class="multiple-choice" />
                <div class="multiple-choice" />
              </div>

              <div class="d-flex flex-row justify-content-center">
                <div class="multiple-choice" />
                <div class="multiple-choice" />
              </div>
            </div>
            <div
              v-if="field.type === 'SCALE'"
              class="d-flex flex-column ml-auto mr-auto mt-1"
            >
              <div class="d-flex flex-row">
                <div
                  v-for="(option, index) in options"
                  :key="index"
                  class="scale-step"
                />
              </div>
              <div class="scale-label">
                <span>
                  {{ field.label.left }}
                </span>
                <span>
                  {{ field.label.right }}
                </span>
              </div>
            </div>
            <div v-if="field.type === 'DROPDOWN'" class="mt-1">
              <div class="dropdown-custom"></div>
            </div>
            <div
              v-if="field.type === 'TEL'"
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <b-icon icon="telephone-fill" font-scale="0.6" />
              <div class="email ml-1"></div>
            </div>
            <div
              v-if="field.type === 'DATE'"
              class="d-flex flex-row align-items-center justify-content-center"
            >
              <div class="date">
                <span>Date</span>
              </div>
              <div class="date ml-1">
                <span>Month</span>
              </div>
              <div class="date ml-1">
                <span>Year</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="card__image-bg h-100 w-100 overflow-hidden">
          <div class="card-field">
            <span class="field-title">{{ form.endPage.title }}</span>
            <span
              class="field-description"
              v-html="form.endPage.description"
            ></span>
          </div>
        </div>
      </div>
      <b-card-body class="pl-3 pt-3 pr-1 pb-2">
        <span class="font-weight-bold">{{ form.title }}</span>
        <div class="d-flex justify-content-between align-items-center">
          <div
            v-if="!archiveSelected"
            class="badge-custom-2"
            :variant="form.version === 'PUBLISHED' ? 'success' : 'secondary'"
          >
            {{
              form.version === "DRAFT"
                ? "DRAFT"
                : form.responseCount > 0
                ? `${form.responseCount} ${$t("responses")}`
                : `${form.responseCount} ${$t("responses")}`
            }}
          </div>
          <div v-else class="badge-custom-2" variant="success">
            {{ $t("notPublished") }}
          </div>
          <b-dropdown
            v-if="!archiveSelected"
            id="dropdown-1"
            variant="link"
            right
            no-caret
          >
            <template #button-content>
              <b-icon-three-dots-vertical />
            </template>
            <b-dropdown-item @click.stop="renameForm">{{
              $t("rename")
            }}</b-dropdown-item>
            <b-dropdown-item>{{ $t("edit") }}</b-dropdown-item>
            <b-dropdown-item @click.stop="duplicateForm">{{
              $t("duplicate")
            }}</b-dropdown-item>
            <b-dropdown
              :disabled="!filteredWorkspaces.length"
              variant="light"
              id="dropdown-2"
              dropright
              no-caret
              block
            >
              <template #button-content>
                <span :style="{ 'padding-left': '12px' }">{{
                  $t("moveTo")
                }}</span>
                <b-icon
                  style="margin-left: 14px; width: 15px;"
                  icon="caret-right-fill"
                />
              </template>
              <b-dropdown-item
                @click.stop="moveForm(workspace)"
                v-for="(workspace, index) in filteredWorkspaces"
                :key="index"
                >{{ workspace.name }}</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown-item @click.stop="archiveForm">{{
              $t("delete")
            }}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown variant="link" no-caret v-else>
            <template #button-content>
              <b-icon-three-dots-vertical />
            </template>
            <b-dropdown
              variant="light"
              id="dropdown-2"
              dropright
              no-caret
              block
            >
              <template #button-content>
                <span :style="{ 'padding-left': '12px' }">{{
                  $t("restoreTo")
                }}</span>
                <b-icon
                  style="margin-left: 14px; width: 15px;"
                  icon="caret-right-fill"
                />
              </template>
              <b-dropdown-item
                @click.stop="restoreForm(workspace)"
                v-for="(workspace, index) in filteredWorkspaces"
                :key="index"
              >
                {{ workspace.name }}
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown-item @click.stop="deleteForm">{{
              $t("deletePermanent")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<i18n>
{
  "en": {
    "rename": "Rename",
    "edit": "Edit",
    "duplicate": "Duplicate",
    "delete": "Delete",
    "deleteForm": "Delete Form",
    "confirmDel": "Are you sure want to delete this form?",
    "cancel": "Cancel",
    "ok": "OK",
    "published": "Published",
    "formClosed": "Form is closed",
    "responses": "responses",
    "moveTo": "Move to",
    "restoreTo": "Restore to",
    "deletePermanent": "Delete permanently",
    "notPublished": "Not published"
  },
  "id": {
    "rename": "Ubah nama",
    "edit": "Edit",
    "duplicate": "Duplikat",
    "delete": "Menghapus",
    "deleteForm": "Hapus Formulir",
    "confirmDel": "Apakah kamu yakin untuk menghapus formulir ini?",
    "cancel": "Batal",
    "ok": "OK",
    "published": "Diterbitkan",
    "formClosed": "Formulir ditutup",
    "responses": "tanggapan",
    "moveTo": "Pindahkan ke",
    "restoreTo": "Pulihkan ke",
    "deletePermanent": "Hapus permanen",
    "notPublished": "Tidak dipublikasikan"
  }
}
</i18n>

<script>
import { differenceInDays, addDays, startOfDay } from "date-fns";

export default {
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    workspaces: {
      type: Array,
      required: true
    },
    archiveSelected: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      response: 0,
      fields: [],
      forms: [],
      options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      isDropdown2Visible: false
    };
  },
  mounted() {
    this.$root.$on("bv::dropdown::show", bvEvent => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", bvEvent => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = false;
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
  },
  computed: {
    fieldsFilter() {
      return this.form.fields.slice(0, 2);
    },
    archiveDate() {
      return differenceInDays(
        addDays(new Date(this.form.archiveDate), 7),
        startOfDay(new Date())
      );
    },
    filteredWorkspaces() {
      if (this.archiveSelected) {
        return this.workspaces;
      } else {
        return this.workspaces.filter(
          workspace => workspace.id !== this.workspaceId
        );
      }
    }
  },
  methods: {
    goToForm() {
      this.$emit("go-to-form");
    },
    deleteForm() {
      this.$emit("delete-form");
    },
    archiveForm() {
      this.$emit("archive-form");
    },
    duplicateForm() {
      this.$emit("duplicate-form");
    },
    renameForm() {
      this.$emit("rename-form");
    },
    moveForm(workspace) {
      this.$emit("move-form", {
        workspaceId: workspace.id,
        workspaceName: workspace.name,
        formId: this.form.id
      });
    },
    restoreForm(workspace) {
      this.$emit("restore-form", {
        workspaceId: workspace.id,
        workspaceName: workspace.name,
        formId: this.form.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card__image-container {
  height: 160px;
}

.card__image-bg {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
  background: #f4f5f6;
}

.card-field {
  width: 174px;
  background: #d0e2f7;
  padding: 22px;
  display: flex;
  flex-direction: column;
}

.field-title {
  font-size: 9px;
  font-weight: 700;
  text-align: center;
}

.field-description {
  font-size: 4px;
  text-align: center;
}

.dropdown-custom {
  width: 80px;
  height: 8px;
  border-radius: 2px;
  background: black;
  margin: auto;
}

.short-text {
  width: 80px;
  height: 8px;
  border: 1px solid black;
  background: white;
  margin: auto;
}

.long-text {
  width: 80px;
  height: 15px;
  background: white;
  margin: auto;
}

.email {
  width: 60px;
  height: 8px;
  background: white;
}

.multiple-choice {
  width: 35px;
  height: 8px;
  background: black;
  border-radius: 2px;
  margin: 2px 2px;
}

.start-page {
  width: 26px;
  height: 8px;
  background: #3881d7;
  font-size: 4px;
  color: white;
  padding: 1px 8px;
  margin-top: 4px;
  margin: auto;
  overflow: hidden;
}

.scale-step {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 2px;
  margin-left: 2px;
}

.scale-label {
  width: 120px;
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 7px;
}

.date {
  width: 30px;
  height: 10px;
  border-radius: 2px;
  background: black;
  font-size: 5px;
  color: white;
  padding: 2px 2px 2px 3px;
}

.badge-custom {
  height: 24px;
  background: #feecea;
  color: #621a15;
  position: absolute;
  z-index: 100;
  font-size: 12px;
  border-radius: 4px;
  padding: 3px 4px;
  top: 8px;
  left: 8px;
}

.badge-custom-archive {
  height: 24px;
  background: #5e6674;
  color: white;
  position: absolute;
  z-index: 100;
  font-size: 12px;
  border-radius: 4px;
  padding: 3px 4px;
  top: 8px;
  left: 8px;
}

.badge-custom-2 {
  height: 24px;
  border-radius: 4px;
  background: #e8f1fb;
  color: #3881d7;
  font-size: 12px;
  padding: 3px 4px;
}

.btn-link {
  color: #5e6674;
}

.card-shadow {
  box-shadow: 0px 2px 10px 2px rgba(184, 184, 184, 0.2);
  -webkit-box-shadow: 0px 2px 10px 2px rgba(184, 184, 184, 0.2);
  -moz-box-shadow: 0px 2px 10px 2px rgba(184, 184, 184, 0.2);
}
</style>
