import { render, staticRenderFns } from "./WorkspaceMain.vue?vue&type=template&id=3d4a7000&scoped=true&"
import script from "./WorkspaceMain.vue?vue&type=script&lang=js&"
export * from "./WorkspaceMain.vue?vue&type=script&lang=js&"
import style0 from "./WorkspaceMain.vue?vue&type=style&index=0&id=3d4a7000&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4a7000",
  null
  
)

/* custom blocks */
import block0 from "./WorkspaceMain.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports